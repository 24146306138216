!function(e){e.fn.beforeafter=function(i){var t=e.extend({touch:!0,message:"",hide_message:!0,reset:!0,reset_delay:3e3,drag_horizontal:!0,split_horizontal:!0
},i);return this.each(function(){var i=e(this),a=i.find("img"),n=a.data("aftersrc"),s=i.width(),d=0;a.after('<div class="g-img-after"><img style="width: '+s+'px;" src="'+n+'"></div>'),
a.addClass("g-img-before").width(s),i.append('<div class="g-img-divider"><span>'+t.message+"</span></div>"),d=i.height(),t.split_horizontal||i.addClass("g-vertical"),
i.on("mouseenter touchstart",function(e){var t=i.data("reset-timer");t&&(window.clearTimeout(t),i.data("reset-timer",!1))}).on("mousemove touchmove",function(a){
var n=0,o=0,r=i.find(".g-img-divider span");if(t.drag_horizontal)n=a.pageX-i.offset().left,o=n/s*100;else{var f=i.offset().top-e(window).scrollTop();n=a.clientY/f,
o=(a.clientY-f)/d*100}if(t.touch&&"undefined"!=typeof a.originalEvent.touches){var g=a.originalEvent.touches[0];o=t.drag_horizontal?(g.pageX-i.offset().left)/s*100:(g.pageY-i.offset().top)/d*100;
}t.split_horizontal?(i.find(".g-img-after").css("left",o+"%"),i.find(".g-img-divider").css("left",o+"%")):(i.find(".g-img-after").css("top",o+"%"),i.find(".g-img-divider").css("top",o+"%")),
t.hide_message&&r.is(":visible")&&r.fadeOut()}).on("mouseleave touchend touchcancel",function(e){var a=i.data("reset-timer"),n=i.find(".g-img-divider span");
t.reset&&(a||(a=window.setTimeout(function(){t.split_horizontal?(i.find(".g-img-after").animate({left:"50%"},500),i.find(".g-img-divider").animate({left:"50%"
},500,function(){n.fadeIn()})):(i.find(".g-img-after").animate({top:"50%"},500),i.find(".g-img-divider").animate({top:"50%"},500,function(){n.fadeIn()})),
i.data("reset-timer",!1)},t.reset_delay),i.data("reset-timer",a)))})}),this}}(jQuery);